import { css } from '@emotion/css';

export const HighlightLight = css`
  pre code.hljs {
    display: block;
    overflow-x: auto;
    padding: 1em;
  }
  code.hljs {
    padding: 3px 5px;
  } /*!
Theme: a11y-light
Author: @ericwbailey
Maintainer: @ericwbailey

Based on the Tomorrow Night Eighties theme: https://github.com/isagalaev/highlight.js/blob/master/src/styles/tomorrow-night-eighties.css
*/
  .hljs {
    background: #fefefe;
    color: #545454;
  }
  .hljs-comment,
  .hljs-quote {
    color: #696969;
  }
  .hljs-deletion,
  .hljs-name,
  .hljs-regexp,
  .hljs-selector-class,
  .hljs-selector-id,
  .hljs-tag,
  .hljs-template-variable,
  .hljs-variable {
    color: #d91e18;
  }
  .hljs-attribute,
  .hljs-built_in,
  .hljs-link,
  .hljs-literal,
  .hljs-meta,
  .hljs-number,
  .hljs-params,
  .hljs-type {
    color: #aa5d00;
  }
  .hljs-addition,
  .hljs-bullet,
  .hljs-string,
  .hljs-symbol {
    color: green;
  }
  .hljs-section,
  .hljs-title {
    color: #007faa;
  }
  .hljs-keyword,
  .hljs-selector-tag {
    color: #7928a1;
  }
  .hljs-emphasis {
    font-style: italic;
  }
  .hljs-strong {
    font-weight: 700;
  }
  @media screen and (-ms-high-contrast: active) {
    .hljs-addition,
    .hljs-attribute,
    .hljs-built_in,
    .hljs-bullet,
    .hljs-comment,
    .hljs-link,
    .hljs-literal,
    .hljs-meta,
    .hljs-number,
    .hljs-params,
    .hljs-quote,
    .hljs-string,
    .hljs-symbol,
    .hljs-type {
      color: highlight;
    }
    .hljs-keyword,
    .hljs-selector-tag {
      font-weight: 700;
    }
  }
`;

export const HighlightDark = css`
  pre code.hljs {
    display: block;
    overflow-x: auto;
    padding: 1em;
  }
  code.hljs {
    padding: 3px 5px;
  } /*!
  Theme: a11y-dark
  Author: @ericwbailey
  Maintainer: @ericwbailey

  Based on the Tomorrow Night Eighties theme: https://github.com/isagalaev/highlight.js/blob/master/src/styles/tomorrow-night-eighties.css
*/
  .hljs {
    background: #2b2b2b;
    color: #f8f8f2;
  }
  .hljs-comment,
  .hljs-quote {
    color: #d4d0ab;
  }
  .hljs-deletion,
  .hljs-name,
  .hljs-regexp,
  .hljs-selector-class,
  .hljs-selector-id,
  .hljs-tag,
  .hljs-template-variable,
  .hljs-variable {
    color: #ffa07a;
  }
  .hljs-built_in,
  .hljs-link,
  .hljs-literal,
  .hljs-meta,
  .hljs-number,
  .hljs-params,
  .hljs-type {
    color: #f5ab35;
  }
  .hljs-attribute {
    color: gold;
  }
  .hljs-addition,
  .hljs-bullet,
  .hljs-string,
  .hljs-symbol {
    color: #abe338;
  }
  .hljs-section,
  .hljs-title {
    color: #00e0e0;
  }
  .hljs-keyword,
  .hljs-selector-tag {
    color: #dcc6e0;
  }
  .hljs-emphasis {
    font-style: italic;
  }
  .hljs-strong {
    font-weight: 700;
  }
  @media screen and (-ms-high-contrast: active) {
    .hljs-addition,
    .hljs-attribute,
    .hljs-built_in,
    .hljs-bullet,
    .hljs-comment,
    .hljs-link,
    .hljs-literal,
    .hljs-meta,
    .hljs-number,
    .hljs-params,
    .hljs-quote,
    .hljs-string,
    .hljs-symbol,
    .hljs-type {
      color: highlight;
    }
    .hljs-keyword,
    .hljs-selector-tag {
      font-weight: 700;
    }
  }
`;
